import React from 'react'
import { ContentSection, } from "@aaa/muse-ds/ContentSection";
import { RichContentWrapper } from "@aaa/muse-ds/RichContentWrapper";
import { SwiperWrapper } from "@aaa/muse-ds/SwiperWrapper";
import { Card } from "@aaa/muse-ds/Card";
import MemberValidationButton from "../special/MemberValidationButton";
import NuxeoRichTextWrapperComponent from "../NuxeoRichTextWrapperComponent";
import SectionHiddenIdComponent from "../special/SectionHiddenIdComponent";

export default function WebCompCarouselStackedBlocks(props){

  const {cards,horizontal,title,content} = props


  let horizontalObject = horizontal ? {"horizontalCardLeftImage":true} : {}


    return <ContentSection
    backgroundColor="#edeef2"
    centerText
  >
    
    <SectionHiddenIdComponent/>
    <Container
      noPadding
      width="980"
    >
      <RichContentWrapper>
        <h2 className="Serif LText Center">
          <strong>{title}</strong>
        </h2>
        <NuxeoRichTextWrapperComponent className="Center" content={content}/>
      </RichContentWrapper>
    </Container>
    <SwiperWrapper
      freeMode
      keyboard
      navigation
      pagination
      swiperAuto
      centerAlign
    >
      {
        cards.map(x=><Card
          ariaLabel={x.title}
          categoryColor="#337b1f"
          categoryTitle={x.categoryTitle}
          contentArea={<><MemberValidationButton ariaLabel="" label="Book Now" size="S" style="NEU_SECONDARY"/></>}
          description={x.description}
          {...horizontalObject}
          imgURL="https://discounts.aaa.com/sites/default/files/styles/discount_card_image/public/2022-01/the-parking-spot-promotion.jpg?itok=NtGaCk5l"
          price="Save 20%"
          subtitle={x.subTitle}
          title={x.title}
        />)
      }
      
    </SwiperWrapper>
  </ContentSection>
}