import React from 'react'
import { AAA_TOMCAT_HOST, AAA_WEBSERVER_HOST,DEFAULT_DOMAIN, DEFAULT_HOST, NUXEO_ENVIRONMENT_DOMAIN, public_path } from "../config/environment.config";
import { renderToString } from 'react-dom/server';
import { getBannerComponentImage } from "./component.utils";
import {SkipToMainContent} from '@aaa/muse-ds/SkipToMainContent'

export const cssLinksFromAssets = (entrypoint) => {
  try{
    const assets = require(process.env.RAZZLE_ASSETS_MANIFEST);
    return assets[entrypoint]
      ? assets[entrypoint].css
        ? assets[entrypoint].css
          .map(
            (asset) => {
              var asset2 = asset
              if(asset.startsWith("/"))asset2 = asset.substring(1)
              return `<link rel="stylesheet" href="${public_path}${asset2}">`
            }
          )
          .join('')
        : ''
      : '';
  }catch(e){return "";}
};

function valueOrMap(v,extra){

  // If the value is a string
  if(typeof v ==="string")return [v];
 
  // Handle as an array
  return v
}

export const jsScriptTagsFromAssets = (entrypoint, extra = '') => {
  try{
    const assets = require(process.env.RAZZLE_ASSETS_MANIFEST);
    return assets[entrypoint] ? (assets[entrypoint].js ? valueOrMap(assets[entrypoint].js,extra) : []) : [];
  }catch(e){
    console.error(e)
    return "";}
};

export const getNuxeoEnvironmentDomain = ()=>{

  if (typeof window === 'undefined' || window.PAGE_DATA==null){

    return NUXEO_ENVIRONMENT_DOMAIN
  }else{

    return JSON.parse(decodeURIComponent(window.PAGE_DATA)).NUXEO_ENVIRONMENT_DOMAIN
  }
}

export const getDefaultHost = (domain)=>{
  if (typeof window === 'undefined' || window.PAGE_DATA==null){

    // use a domain if specified
    if(domain)return `https://${domain}`;

    return DEFAULT_HOST;

  }else{

    // use a domain if specified
    // no protocol if we are in the browser
    if(domain)return `//${domain}`;

    return JSON.parse(decodeURIComponent(window.PAGE_DATA)).DEFAULT_HOST;

  }
}
export const getDefaultDomain = ()=>{
  if (typeof window === 'undefined' || window.PAGE_DATA==null){

    return DEFAULT_DOMAIN;

  }else{

    return JSON.parse(decodeURIComponent(window.PAGE_DATA)).DEFAULT_DOMAIN;

  }
}


export const performServerSideRendering = (req,res,element,header,footer,pageData,statusCode)=>{
    
  var didError=false;
    
    res.write(performServerSideRenderingHalf1(req,  header,pageData));
    
    if(req.jest){
      res.status(statusCode||200);
      res.write(renderToString(element));
      res.write(performServerSideRenderingHalf2(footer));
    }else{
    
      const stream = renderToPipeableStream(
        element,
        {
            bootstrapScripts: [...jsScriptTagsFromAssets( 'client')],
            onShellReady: () => {
                res.status(didError ? 500 : (statusCode||200));
                stream.pipe(res);
            },
            onAllReady:() => {
              res.write(performServerSideRenderingHalf2(footer));

            },
            onError: (error) => {
                didError = true;
            } 
        }
    );
  }

    

}

  export const performServerSideRenderingHalf1 = (req,header,pageData)=>{
    
    const skipToMainContent = renderToString(<SkipToMainContent sectionTarget="root" label="Skip to Main Content"/>)
    const html =
      // prettier-ignore
      `<!doctype html>
          <html lang="en">
          <head>
              ${getSSRHeadSectionCOntent(req,pageData)}
          </head>
          <body style="background-color:#dee0e7">
            ${skipToMainContent}
            <header id="aaa-main-header-section">${header}</header>
            <main>
            <div id="root">
          
          `;
  
    return html;
  }
  export const performServerSideRenderingHalf2 = (footer)=>{
      
      const html =
        // prettier-ignore
        `   </div>
            </main>
            <footer id="aaa-main-footer-section">${footer}</footer>
            </body>
        </html>`;
    
      return html;
    }

  import { renderToPipeableStream } from 'react-dom/server';
  
function isValidDomain(domain) {
  const domainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9-]{1,63})*(\.[A-Za-z]{2,})$/;
  return domainRegex.test(domain) && (domain.indexOf("aaa.com")!=-1||domain.indexOf("aaa.biz")!=-1);
}

export const getSSRHeadSectionCOntent = (req,pageData)=>{
  
  const keywords = ((pageData.metaTags|| {}).keywords|| []);

  const metaTags = (pageData.metaTags || {og:{},twitter:{},description:null,title:"AAA.com SEO Landing Pages"})

  const title = metaTags.title||pageData.nuxeoDocumentTitle||"AAA.com SEO Landing Pages"
  const description = metaTags.description||"AAA.com SEO Landing Page Description"

  const site = (pageData.site||"development").toLowerCase();
  const siteSlugForm = site.replace(/\W/g, '').trim();

  const globalStyle = (global && global.ssrStyles) ? (global?.ssrStyles?.join(' ')) : "";

  var requestHost = req.get('host');

  var fullUrl = DEFAULT_HOST;

  if(isValidDomain(requestHost)){
    fullUrl = req.protocol + '://' + requestHost + req.originalUrl;
  }


  const secureOGIMage = metaTags.og.image || getBannerComponentImage(pageData);
  const nonSecureOGImage = (secureOGIMage||"").replace("https://","http://");
  
  var taggingElements = `
      <script type="text/javascript" async src="${AAA_WEBSERVER_HOST}/AAA/common/javascripts/commonlogging.js" disableAutomaticTagging="true"></script>
      <script type="text/javascript" async src="${AAA_WEBSERVER_HOST}/configuration/sem/LoggingCommon.js"></script>
  `;

  // Hardcoding nos google removal
  if(fullUrl.indexOf("networkofsavings.aaa.biz")!=-1||site.trim().toLowerCase()=="networkofsavings"){
    taggingElements = `
      <script type="text/javascript" async src="${AAA_WEBSERVER_HOST}/AAA/common/javascripts/commonlogging.js" disableAutomaticTagging="true"></script>
  `;
  }

  const analyticsScriptTags = (pageData.tags||[]).includes("disable-analytics")? "": taggingElements;

  if((pageData.tags||[]).includes("disable-analytics")){
    console.log("disabling analytics for "+title)
  }

  var canoncial = "";

  try{

    // Look for our page tag that starts with 'canonical='
    var canoncialTag =pageData.canonical

    // If we have a canoncial tag
    if(canoncialTag){

      // Set the element we'll use
      canoncial = `<link rel="canonical" href="${canoncialTag}" />`;
    }
  }catch(e){
    console.log("unable to add canoncial tag for page")
    console.log(e)
  }

  const html =
    // prettier-ignore
    `
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta charSet='utf-8' />
          <meta name="keywords" content="${keywords.join(",")}">

          <meta name="description" content="${description}">

          ${canoncial}

          <meta property="og:type" name="og:type" content="${metaTags.og.type||"website"}" />
          <meta property="og:url" name="og:url" content="${metaTags.og.url || fullUrl}" />
          <meta property="og:title" name="og:title" content="${metaTags.og.title || title}" />
          <meta property="og:description" name="og:description" content="${metaTags.og.description || description}" />
          <meta property="og:image:secure_url" name="og:image:secure_url" content="${secureOGIMage}" />
          <meta property="og:image" name="og:image" content="${nonSecureOGImage}" />

          <meta property="twitter:card" name="twitter:card" content="${metaTags.twitter.card||"summary"}" />
          <meta property="twitter:site" name="twitter:site" content="${metaTags.twitter.site || "@aaanationalusa"}" />
          <meta property="twitter:title" name="twitter:title" content="${metaTags.twitter.title || title}" />
          <meta property="twitter:description" name="twitter:description" content="${metaTags.twitter.description || description}" />
          <meta property="twitter:image" name="twitter:image" content="${metaTags.twitter.image || getBannerComponentImage(pageData)}" />
          <link rel="preload" fetchpriority="high" href="${secureOGIMage}" as="image" type="image/webp" />

          <title>${title}</title>
          <link rel="icon" type="image/x-icon" href="https://www.aaa.com/AAA/common/images/aaa_services/aaa-fav-icons/favicon-16x16.png">
          
          <meta name="viewport" content="width=device-width, initial-scale=1"><script type="text/javascript">
              window.LOCAL_PUBLIC_PATH = '${__webpack_public_path__}';
              window.PAGE_DATA = \"${encodeURIComponent(JSON.stringify(pageData))}\";
          </script>
          ${(pageData.specialMetaTags||[]).map(x=>`<meta name=\"${x.name}\" content=\"${x.content}\" />`).join("\n")}
          <script type="text/javascript" async src="${AAA_TOMCAT_HOST}/sharedservices/membervalidation.js?vendor=member-validation-widget-${siteSlugForm}"></script>
          <script type="text/javascript" async src="${AAA_WEBSERVER_HOST}/jsincludes/zipcodeServices.js"></script>
          ${analyticsScriptTags}
          <link rel="preload" as="font" href="https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
          ${cssLinksFromAssets( 'client')}
          <!-- design system styles. -->
          <style>${globalStyle.replace(/[\r\n]/gm, '')}</style>
          
          <style>
          html * {
              box-sizing: border-box;
          }
          </style>
        `;

  return html;
}